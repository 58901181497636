import { DiAndroid, DiApple, DiCss3 } from "react-icons/di";
import { RiStackLine, RiPencilRuler2Line, RiFileCopy2Line, RiFileCodeLine, RiGitPullRequestLine, RiGithubFill, RiContrastLine } from "react-icons/ri";
import ThemedImage from "$components/ThemedImage";
import FileTree from "$components/FileTree";
import TokenStructure from "../../../../src/blog/dark-mode-with-style-dictionary/TokenStructure";
import "../../../../src/blog/dark-mode-with-style-dictionary/dark-mode-styles.css";
import * as React from 'react';
export default {
  DiAndroid,
  DiApple,
  DiCss3,
  RiStackLine,
  RiPencilRuler2Line,
  RiFileCopy2Line,
  RiFileCodeLine,
  RiGitPullRequestLine,
  RiGithubFill,
  RiContrastLine,
  ThemedImage,
  FileTree,
  TokenStructure,
  React
};