import React from 'react';
import * as logoStyles from './Logo.module.css';

const Logo = () => (
  <span className={logoStyles.logo}>
    <svg version="1.1" viewBox="0 0 360 360">
      <g className={logoStyles.g} transform="translate(-20.000000, -20.000000)">
        <rect id="Rectangle" x="20" y="20.0000025" width="60" height="360"></rect>
        <path d="M80,20.0000025 C179.411255,20.0000025 260,100.588748 260,200.000003 C260,299.411257 179.411255,380.000003 80,380.000003 L80,320.000003 C146.27417,320.000003 200,266.274172 200,200.000003 C200,133.725833 146.27417,80.0000025 80,80.0000025 L80,20.0000025 Z" ></path>
        <path d="M275,20.0000025 C332.989899,20.0000025 380,67.0101038 380,125.000003 C380,154.380327 367.93299,180.94225 348.484738,200.000006 C367.932992,219.057758 380,245.61968 380,275.000003 C380,332.989901 332.989899,380.000003 275,380.000003 L275,320.000003 C299.852814,320.000003 320,299.852816 320,275.000003 C320,250.147189 299.852814,230.000003 275,230.000003 L275,170.000003 C299.852814,170.000003 320,149.852816 320,125.000003 C320,100.147189 299.852814,80.0000025 275,80.0000025 L275,20.0000025 Z" ></path>
        <path d="M174.000003,20.0000025 L275.772406,20.0000025 L275.772406,80.0000025 L238.131727,80.0000025 C221.236154,55.7777426 199.365083,35.2839133 174,20 Z"></path>
        <path d="M174.000003,320.000005 L275.772406,320.000005 L275.772406,380.000005 L238.131727,380.000005 C221.236154,355.777745 199.365083,335.283916 174,320.000003 Z" transform="translate(224.886203, 350.000004) scale(1, -1) translate(-224.886203, -350.000004) "></path>
      </g>
    </svg>
  </span>
);

export default Logo;