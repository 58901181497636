import Autonomy from "../../../../src/blog/multi-platform/Autonomy";
import Cohesion from "../../../../src/blog/multi-platform/Cohesion";
import Definitions from "../../../../src/blog/multi-platform/Definitions";
import DesignTokens from "../../../../src/blog/multi-platform/DesignTokens";
import Devices from "../../../../src/blog/multi-platform/Devices";
import Distribute from "../../../../src/blog/multi-platform/Distribute";
import Foundations from "../../../../src/blog/multi-platform/Foundations";
import Meet from "../../../../src/blog/multi-platform/Meet";
import MultiPlatform from "../../../../src/blog/multi-platform/MultiPlatform";
import Radar from "../../../../src/blog/multi-platform/Radar";
import Rely from "../../../../src/blog/multi-platform/Rely";
import Systems from "../../../../src/blog/multi-platform/Systems";
import * as React from 'react';
export default {
  Autonomy,
  Cohesion,
  Definitions,
  DesignTokens,
  Devices,
  Distribute,
  Foundations,
  Meet,
  MultiPlatform,
  Radar,
  Rely,
  Systems,
  React
};